<template>
<div class="page_content_wrap">
	<div class="expennses_wrap py-5">
		<div class="container">
			<div class="expennses_box">
          <h2 class="text-dark mb-2">
              Great! Now let’s assess your income and expenses.
          </h2>
          <p class="text-dark mb-5 font-size-lg">Use the sliders to estimate your finances.</p>
          <div class="text-center mb-5 expennses_box_img">
            <img class="img-fluid" src="/images/expennses.svg" alt="" />
          </div>
          <div class="btn_continue_wrap">
            <button @click="nextPage" class="btn btn-primary btn-lg btn-block"> CONTINUE<i class="fas fa-arrow-right pl-1"></i></button>
          </div>
			</div>
		</div>
	</div>

</div>
</template>


<script>
import { mapGetters, mapActions } from 'vuex';
export default {
  name: 'Screen8',
  methods: {
	  ...mapActions("wizard", {
      updateProgress: "updateProgress",
    }),
    nextPage() {
      if(this.survey.are_you_retired == 'Retired')  {
        let userObj = {
          riskLink : 'risk.fixedIncome'
        };
        this.updateProgress({ step: 1, data: userObj });
        this.$router.push({ name : 'risk.fixedIncome'});
      } else {
        let userObj = {
          riskLink : 'risk.disposableIncome'
        };
        this.updateProgress({ step: 1, data: userObj });
        this.$router.push({ name : 'risk.disposableIncome'});
      }
    },
  },
  computed: {
    ...mapGetters("wizard", {
      progress: "getProgress",
      survey: "getSurvey"
    }),
  }
};
</script>
